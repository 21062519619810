import { format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const formatRelativeDate = (
  dateString: string = new Date().toISOString(),
  dateFormat = 'dd MMMM yyyy',
  timeZone?: string
): string => {  
  const parsedDate = parseISO(dateString);

  if (!timeZone) {
    return format(parsedDate, dateFormat);
  }

  const zonedDate = toZonedTime(parsedDate, timeZone);

  return format(zonedDate, dateFormat);
}