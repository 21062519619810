export interface News {
  id: number;
  title: string;
  publishTime: string;
  link: string;
}

export enum NewsSortEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}
