import { getTimeFrameStartDate } from '@/libs/utils';
import { Request, FilterProps, NewsAnalysis } from '@/libs/types';

import { apiClient } from '../apiClient';

export const getNewsAnalysis = async (params: FilterProps) => {
  const response = await apiClient.get<Request<NewsAnalysis[]>>(`news-analysis`, { 
    params: { 
      ...params, 
      sort: params.sort ? getTimeFrameStartDate(params.sort) : undefined,
    } 
  });

  return response.data;
};