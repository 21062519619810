
import { SentimentEnum } from '@/libs/types';
import { Request, FilterProps } from '../../types';

import { apiClient } from '../apiClient';

interface NewsSentimentSumResponce {
  sentimentSum: Array<[SentimentEnum, number]>;
}

export const getNewsSentimentSum = async (params: FilterProps) => {
  const response = await apiClient.get<Request<NewsSentimentSumResponce>>(`news-analysis/sentiment-sum`, { 
    params 
  });

  return response.data.data.sentimentSum;
};