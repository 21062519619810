import { startOfDay, endOfDay, startOfMonth, subDays, subMonths } from 'date-fns';

import { NewsSortEnum } from "@/libs/types";

export function getDateRange(intervalType: NewsSortEnum) {
  const now = new Date();
  switch (intervalType) {
    case NewsSortEnum.DAY:
      return { start: startOfDay(now), end: endOfDay(now), interval: 'hour' };
    case NewsSortEnum.WEEK:
      return { start: startOfDay(subDays(now, 7)), end: endOfDay(now), interval: 'day' };
    case NewsSortEnum.MONTH:
      return { start: startOfDay(subDays(now, 30)), end: endOfDay(now), interval: 'day' };
    case NewsSortEnum.YEAR:
      return { start: startOfMonth(subMonths(now, 11)), end: endOfDay(now), interval: 'month' };
  }
}