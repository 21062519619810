import { Theme } from '@mui/material/styles';

export const MuiChip: NonNullable<Theme['components']>['MuiChip'] = {
  styleOverrides: {
    root: {
      backgroundColor: '#F5F8F5',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '12px',
      color: '#000000',
    },
  },
};

