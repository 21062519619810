
import { SentimentEnum } from '@/libs/types';
import { Request, FilterProps } from '../../types';

import { apiClient } from '../apiClient';

type NewsSentimentSumResponce = Array<[SentimentEnum, number]>

export const getImportanceSumBySentiment = async (params: FilterProps) => {
  const response = await apiClient.get<Request<NewsSentimentSumResponce>>(`news-analysis/importance-sentimen-sum`, { 
    params 
  });

  return response.data.data;
};