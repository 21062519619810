import { Theme } from '@mui/material/styles';

export const MuiOutlinedInput: NonNullable<Theme['components']>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      border: '1px solid #E9EAEB',
      borderRadius: '100px',
    },
  },
};

