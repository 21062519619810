import MuiLink, { LinkProps } from '@mui/material/Link';
import { Ref, forwardRef } from 'react';

export const Link = forwardRef(
  ({ children, ...rest }: LinkProps, ref: Ref<HTMLAnchorElement>) => {
    return (
      <MuiLink {...rest} ref={ref}>
        {children}
      </MuiLink>
    );
  },
);

Link.displayName = 'Link';