import { unstable_noStore as noStore } from 'next/cache';

import { apiClient } from '../apiClient';

interface TickerQuote {
  symbol: string;
  price: number;
}

export const getTickerQuote = async (symbol: string): Promise<TickerQuote> => {
  noStore();

  // todo inplement some cache in nextjs side
  const response = await apiClient.get(`tickers/${symbol}/price`);

  return response.data;
};