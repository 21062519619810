import { InfiniteData } from "@tanstack/react-query";
import { RequestWithMeta, Request } from "@/libs/types";

export const entitySelector = <T = unknown>(data?: Request<T>) => ({
  entities: data?.data ?? null,
});

export const entitiesInfiniteSelector = <T>(
  data: InfiniteData<RequestWithMeta<T>>,
) => ({
  entities: data.pages.flatMap((page) => page.data ?? []),
  meta: data.pages[data.pages.length - 1]?.meta,
});