import { forwardRef, Ref } from 'react';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

export const TextField = forwardRef(
  function TextFieldComponent(props: TextFieldProps, ref: Ref<HTMLInputElement>) {
    return <MuiTextField {...props} ref={ref} fullWidth />;
  },
);

TextField.displayName = 'TextField';
