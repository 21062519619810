export type WithRequired<T, K extends keyof T> = T & Required<Pick<T, K>>;
export type PaginationObserverRef<T = HTMLLIElement> =
  | null
  | ((node: T) => void);

export type ObjectKey = string | number | symbol;

type DataTestId = {
  'data-testid': string;
};

export enum SentimentEnum {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL'
}

export enum CategoryEnum {
  NASDAQ = 'NASDAQ'
}

export enum CategorySectorEnum {
  BASIC_MATERIALS = 'BASIC_MATERIALS',
  CONSUMER_DISCRETIONARY = 'CONSUMER_DISCRETIONARY',
  CONSUMER_STAPLES = 'CONSUMER_STAPLES',
  ENERGY = 'ENERGY',
  FINANCE = 'FINANCE',
  HEALTH_CARE = 'HEALTH_CARE',
  INDUSTRIALS = 'INDUSTRIALS',
  MISCELLANEOUS = 'MISCELLANEOUS',
  REAL_ESTATE = 'REAL_ESTATE',
  TECHNOLOGY = 'TECHNOLOGY',
  TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
  UTILITIES = 'UTILITIES'
}

export type WithDataTestId<
  T,
  OPTIONAL extends boolean = false,
> = OPTIONAL extends true ? T & Partial<DataTestId> : T & DataTestId;

export type DeepPartial<T> = T extends unknown[]
  ? T
  : T extends Record<string, unknown>
    ? {
        [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T;

    