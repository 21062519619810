import { Theme } from '@mui/material/styles';

export const MuiButton: NonNullable<Theme['components']>['MuiButton'] =
  {
    styleOverrides: {
      root: ({
        borderRadius: '4px',
        textTransform: 'none',
        fontWeight: 600,
      }),
    },
    variants: [
      {
        props: { variant: 'outlined' },
        style: {
          boxShadow: 'none', 
        },
      },
      {
        props: { variant: 'outlined', color: 'primary' },
        style: {},
      },
      {
        props: { variant: 'outlined', color: 'secondary' },
        style: {},
      },
      {
        props: { variant: 'outlined', color: 'info' },
        style: {},
      },
      {
        props: { variant: 'text' },
        style: {
          boxShadow: 'none',
        },
      },
      {
        props: { variant: 'text', color: 'info' },
        style: {},
      },
    ],
  };
