'use client';
import { Urbanist } from 'next/font/google';
import { createTheme } from '@mui/material/styles';
import { createStyled } from '@mui/system';

import * as overrides  from './overrides';
import { palette } from './palette';

export const urbanistFont = Urbanist({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});


export const theme = createTheme({
  palette,
  typography: {
    fontFamily: urbanistFont.style.fontFamily,
  },
  components: overrides,
});

export const styled = createStyled({ defaultTheme: theme });
