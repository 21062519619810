import { PropsWithChildren, ReactNode } from 'react';
import { Box } from '@mui/material';

interface CircularBadgeProps {
  color?: string;
  borderColor?: string;
  size?: number;
  children: ReactNode;
}

export const CircularBadge = ({
  children,
  color = '#F5F8F5', 
  borderColor = '#E9EAEB', 
  size = 38, 
}: PropsWithChildren<CircularBadgeProps>) => (
  <Box
    width={`${size}px`}
    height={`${size}px`}
    borderRadius="50%"
    border={`1px solid ${borderColor}`}
    display="flex"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    bgcolor={color}
  >
    {children}
  </Box>
);

