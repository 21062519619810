import { Theme } from '@mui/material/styles';

export const MuiLink: NonNullable<Theme['components']>['MuiLink'] = {
  styleOverrides: {
    root: () => ({
      cursor: 'pointer',
      ':hover': {
        opacity: 0.7,
        textDecoration: 'underline',
      },
    }),
  },
};
