import { Ref, forwardRef } from 'react';

import MuiTypography, { TypographyProps } from '@mui/material/Typography';

export const Typography = forwardRef(
  ({ children, ...rest }: TypographyProps, ref: Ref<HTMLSpanElement>) => {
    return (
      <MuiTypography {...rest} ref={ref}>
        {children}
      </MuiTypography>
    );
  },
);

Typography.displayName = 'Typography';
