import { NewsSortEnum } from '@/libs/types';
import { subDays, subWeeks, subMonths, subYears } from 'date-fns';

export const getTimeFrameStartDate = (sort: NewsSortEnum): string => {
  let result;

  const now = new Date();
  
  switch (sort) {
    case NewsSortEnum.DAY:
      result = subDays(now, 1);
      break;
    case NewsSortEnum.WEEK:
      result = subWeeks(now, 1);
      break;
    case NewsSortEnum.MONTH:
      result = subMonths(now, 1);
      break;
    case NewsSortEnum.YEAR:
      result = subYears(now, 1);
      break;
    default:
      result = now;
  }

  return result.toISOString().split('T')[0];
};