export const palette = {
  primary: {
    main: 'rgba(0, 0, 0, 0.87)',
  },
  divider: 'rgba(233, 234, 235, 1)',
  common: {
    black: '#000',
    white: '#FFF',
  },
  trend: {
    negative: '#F8A4A1',
    positive: '#BEE8BE',
    neutral: '#F3F3F3'
  },
  link: {
    main: '#E9A43E',
    hover: '#EED398',
  },
  progress: {
    active: '#F8CE72',
    inactive: '#F8F4DE',
  },
  text: {
    primary: 'rgba(29, 30, 31, 0.87)',
    secondary: 'rgba(29, 30, 31, 0.56)',
  },
};

export type CustomPalette = typeof palette;
