import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { FilterProps, News, RequestWithMeta } from '@/libs/types';

import { apiClient } from '../apiClient';
import { getQueryClient } from '../get-query-client';
import { entitiesInfiniteSelector } from '@/libs/utils';

const PAGE_SIZE = 6;

interface NewsQueryParams extends Omit<FilterProps, 'sort'> {
  page?: number;
  perPage?: number;
}

export const NEWS_QUERY_KEY = 'news';

export const getNews = async (params: NewsQueryParams) => {
  const response = await apiClient.get<RequestWithMeta<News[]>>('/news', { 
    params: {
      ...params,
      perPage: PAGE_SIZE,
    },
   });
   
  return response.data;
};

export const useNewsSuspenseInfiniteQuery = (params: FilterProps) => {
  const { sort, ...newsFilter } = params;

  return useSuspenseInfiniteQuery({
    queryKey: [NEWS_QUERY_KEY, newsFilter],
    queryFn: ({ pageParam = 1 }) => getNews({ ...newsFilter, page: pageParam  }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, page) => {        
      if (page < lastPage?.meta?.lastPage) return page + 1;
    },
    select: entitiesInfiniteSelector,
  });
};

export const prefetchNewsSuspenseInfiniteQuery = (params: FilterProps) => {
  const { sort, ...newsFilter } = params;

  const queryClient = getQueryClient()

  queryClient.prefetchInfiniteQuery({
    queryKey: [NEWS_QUERY_KEY, newsFilter],
    queryFn: () => getNews(newsFilter),
    initialPageParam: 1
  })

  return queryClient;
}