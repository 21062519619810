import { format, parseISO } from 'date-fns';

import { FilterProps, NewsSortEnum } from '@/libs/types';
import { getDateRange } from '@/libs/utils';

import { apiClient } from '../apiClient';

interface TickerHistorical {
  date: string;
  close: number;
}

const getPeriod = (sort: NewsSortEnum): string => {
  switch (sort) {
    case NewsSortEnum.WEEK:
    case NewsSortEnum.MONTH:
      return 'd';
    case NewsSortEnum.YEAR:
      return 'm';
    default:
      return 'd';
  }
};

export const getTickerHistorical = async (params: FilterProps) => {
  const sort = params.sort === NewsSortEnum.DAY ? NewsSortEnum.WEEK : params.sort;
  const { start, end } = getDateRange(sort);
  const period = getPeriod(sort);

  const response = await apiClient.get<TickerHistorical[]>(`tickers/${params.symbol}/historical`, {
    params: {
      period,
      from: format(start, 'yyyy-MM-dd'),
      to:  format(end, 'yyyy-MM-dd'),
    },
  });

  const result = response.data.map(data => {
    const date = parseISO(data.date);
    return {
      label: params.sort === NewsSortEnum.YEAR ? format(date, 'MMM yyyy') : format(date, 'MMM dd'),
      price: data.close
    };
  });

  if (sort === NewsSortEnum.YEAR) {
    return {
      labels: result.map(r => r.label).slice(1),
      prices: result.map(r => r.price).slice(1),
    };
  }

  return {
    labels: result.map(r => r.label),
    prices: result.map(r => r.price),
  };
};
