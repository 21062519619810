
import { Request, FilterProps } from '../../types';

import { apiClient } from '../apiClient';

interface NewsSentimentCountsResponce {
  sentiments: {
    labels: string[];
    positive: number[];
    negative: number[];
  };
  total: number;
}

export const getNewsSentimentCounts = async (params: FilterProps) => {
  const response = await apiClient.get<Request<NewsSentimentCountsResponce>>(`news-analysis/sentiment-counts`, { 
    params 
  });

  return response.data.data;
};