import { FilterProps, Request } from '@/libs/types';
import { getTimeFrameStartDate } from '@/libs/utils';

import { apiClient } from '../apiClient';

interface CountResponse {
  count: number
}

export const getNewsCount = async (params: FilterProps) => {  
  const response = await apiClient.get<Request<CountResponse>>('/news/count', { 
    params: {
      ...params,
      sort: params.sort ? getTimeFrameStartDate(params.sort) : undefined,
    },
   });
   
  return response.data.data.count;
};
