import { SentimentEnum , Request, FilterProps } from '@/libs/types';

import { apiClient } from '../apiClient';

interface NewsSentimentSumResponce {
  importance: Record<SentimentEnum, Record<string, number>>;
}

export const getNewsImportancePercentage = async (params: FilterProps) => {
  const response = await apiClient.get<Request<NewsSentimentSumResponce>>(`news-analysis/importance-percentage`, { 
    params 
  });

  return response.data.data;
};