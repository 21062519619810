import { Theme } from '@mui/material/styles';

export const MuiLinearProgress: NonNullable<
Theme['components']
>['MuiLinearProgress'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      // @ts-ignore
      backgroundColor: theme.palette.progress.inactive,
    }),
    bar: ({ theme }) => ({
      // @ts-ignore
      backgroundColor: theme.palette.progress.active,
    })
  }
}